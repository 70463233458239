import React from "react";
import Footer from "../Footer/Footer";

export default function Privacy() {
  return (
    <>
      <section id="skills">
        <div className="container">
          <p>
            <h2>PRIVACY POLICY</h2>
          </p>
          <h4>
            <p className="description">
              This is the privacy notice of IYKONS Limited and other members of
              the IYKONS Group. We respect your privacy and are determined to
              protect your personal data. The purpose of this privacy notice is
              to inform you as to how we look after your personal data when you
              visit our website (regardless of where you visit it from). We’ll
              also tell you about your privacy rights and how the United Kingdom
              Data Protection Act of 2018 protects you. You agree with all
              matters relating to access or use of our website, including all
              disputes, will be governed by English law. This Privacy Policy
              does not apply to personal information that we collect about
              IYKONS employees and other personnel, contractors, or applicants
              and candidates. This privacy notice is provided in a layered
              format so you can click through to the specific areas set out
              below. Alternatively you can download a pdf version of the policy
            </p>
          </h4>

          <h4 className="title">
            <a href="https://web.archive.org/web/20220625024142/https://www.iykons.com/Document/IYKONS_Privacy_Policy.pdf">
              Click Here
            </a>
          </h4>
          <p className="description">
            <p>1. WHO WE ARE AND IMPORTANT INFORMATION</p>

            <p>2. THE PERSONAL DATA WE COLLECT ABOUT YOU</p>

            <p>3. HOW WE COLLECT YOUR PERSONAL DATA</p>

            <p>4. HOW WE USE YOUR PERSONAL DATA</p>

            <p>5. WHO WE SHARE YOUR PERSONAL DATA WITH</p>

            <p>6. INTERNATIONAL TRANSFERS</p>

            <p>7. DATA SECURITY</p>

            <p>8. DATA RETENTION</p>

            <p>9. YOUR LEGAL RIGHTS</p>

            <p>
              10. CHANGES TO THIS NOTICE AND YOUR DUTY TO INFORM US OF CHANGES
            </p>
            <p>11. QUERIES, REQUESTS OR CONCERNS</p>
          </p>

          <h4 className="title">
            <a href="">1.WHO WE ARE AND IMPORTANT INFORMATION</a>
          </h4>

          <h4 className="title">
            <a href="">What is the purpose of this privacy notice?</a>
          </h4>

          <p className="description">
            <p>
              This privacy notice aims to give you information on how we collect
              and process your personal data through your use of this website,
              including any data you may provide through this website when you
              SIGN UP TO RECEIVE OUR NEWSLETTER/ PURCHASE A PRODUCT OR SERVICE/
              REGISTER FOR OUR SERVICES.
            </p>

            <p>
              This website is not intended for children and we do not knowingly
              collect data relating to children.
            </p>

            <p>
              You must read this privacy notice together with any other privacy
              notice we may provide on specific occasions when we are collecting
              or processing personal data about you so that you are fully aware
              of how and why we are using your data. This privacy notice
              supplements the other notices and is not intended to override
              them.
            </p>
          </p>

          <h4 className="title">
            <a href="">Data Controllers</a> 
          </h4>

          <p className="description">
            <p>
              IYKONS LIMITED is the controller and responsible for your personal
              data (collectively referred to as [“COMPANY”], “we”, “us” or “our”
              in this privacy notice).
            </p>

            <p>Our contact details are:</p>

            <p>
              Address: 14 Elm Road, Chessington, Surrey, KT9 1AW, United
              Kingdom.
            </p>

            <p>Email: info@iykons.com</p>

            <p>Telephone: +44 20 3598 2904</p>

            <p>
              For all data matters contact DATA PROTECTION OFFICER on
              DATA@IYKONS.COM
            </p>

            <p>
              IYKONS LIMITED is the controller and responsible for this website.
            </p>
          </p>

          <h4 className="title">
            <a href="">Third-party links outside of our control</a>
          </h4>

          <p className="description">
            <p>
              This website may include links to third-party websites, plug-ins,
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements.
            </p>
            <p>
              When you leave our website, we encourage you to read the privacy
              notice of every website you visit.
            </p>
          </p>
          <h4 className="title">
            <a href="">2.THE PERSONAL DATA WE COLLECT ABOUT YOU</a>
          </h4>

          <p className="description">
            <p>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. You
              can find out more about personal data from the Information
              Commissioner’s Office.
            </p>
            <p>
              We may collect, use, store, and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </p>
            <p>
              <strong>Identity Data</strong> includes FIRST NAME/LAST NAME/
              USERNAME/MARITAL STATUS/ TITLE/DATE OF BIRTH/GENDER.
            </p>
            <p>
              <strong>Contact Data</strong> includes BILLING
              ADDRESS/DELIVERY/POSTAL ADDRESS/EMAIL ADDRESS/ TELEPHONE NUMBERS.
            </p>
            <p>
              <strong>
                WE ALSO COLLECT, USE AND SHARE AGGREGATED DATA SUCH AS
                STATISTICAL OR DEMOGRAPHIC DATA FOR ANY PURPOSE.
              </strong>{" "}
              Aggregated data may be derived from your personal data but is not
              considered personal data in law as this data does not directly or
              indirectly reveal your identity. For example, we may aggregate
              your usage data to calculate the percentage of users accessing a
              specific website feature. However, if we combine or connect
              aggregated data with your personal data so that it can directly or
              indirectly identify you, we treat the combined data as personal
              data which will be used in accordance with this privacy notice.
            </p>

            <p>
              We do not collect any Special Categories of Personal Data about
              you (this includes details about your race or ethnicity, religious
              or philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>

            <p>If you fail to provide personal data</p>

            <p>
              Where we need to collect your personal data by law, or under the
              terms of a contract we have with you and you fail to provide that
              data when requested, we may not be able to perform the contract we
              have or are trying to enter into with you (for example, to provide
              you with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
          </p>

          <h4 className="title">
            <a href="">3. How we are collect your personal data</a>
          </h4>

          <p className="description">
            <p>
              Directly. You may give us your IDENTITY, CONTACT, AND FINANCIAL
              DATA by filling in forms or by corresponding with us by post,
              phone, email, or otherwise. This includes personal data you
              provide when you:
            </p>

            <ul>
              <li>Apply for our products or services;</li>
              <li>Create an account on our website;</li>
              <li>Subscribe to our service or publications;</li>
              <li>Request marketing to be sent to you.</li>
            </ul>
          </p>

          <h4 className="title">4. HOW WE USE YOUR PERSONAL DATA</h4>

          <p className="description">
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <p>
              <strong>Performance of Contract:</strong> This means processing
              your data where it is necessary for the performance of a contract
              to which you are a party or to take steps at your request before
              entering into such a contract.
            </p>
            <p>
              <strong>Legitimate Interest:</strong> This refers to the interest
              of our business in conducting and managing our business to enable
              us to provide you with the best service/product and the most
              secure experience. We ensure we consider and balance any potential
              impact on you (both positive and negative) and your rights before
              processing your personal data for our legitimate interests. We do
              not use your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you by contacting the Data
              Protection Officer.
            </p>
            <p>
              <strong>Comply with a Legal or Regulatory Obligation:</strong>{" "}
              This means processing your personal data where it is necessary for
              compliance with a legal or regulatory obligation that we are
              subject to.
            </p>
            <p>
              <strong>
                GENERALLY WE DO NOT RELY ON CONSENT AS A LEGAL BASIS FOR
                PROCESSING YOUR PERSONAL DATA OTHER THAN IN RELATION TO SENDING
                THIRD PARTY DIRECT MARKETING COMMUNICATIONS TO YOU VIA EMAIL OR
                TEXT MESSAGE.
              </strong>{" "}
              You have the right to withdraw consent to marketing at any time by
              contacting the Data Protection Officer.
            </p>
          </p>

          <p className="description">
            <p>
              <strong>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</strong>
            </p>
            <p>
              We have set out below, in a table format, a description of{" "}
              <strong>all the ways we plan to use your personal data</strong>,
              with the legal bases we rely on to do so.
            </p>
            <p>
              Note that we may process your personal data for more than one
              lawful ground depending on{" "}
              <strong>
                the specific purpose for which we are using your data
              </strong>
              . Please contact <strong>DATA PROTECTION OFFICER</strong> if you
              need details about{" "}
              <strong>
                the specific legal ground we are relying on to process your
                personal data
              </strong>{" "}
              where more than one ground has been set out in the table below.
              <p></p>
            </p>
          </p>
          <table className="table">
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>Purpose/Activity</th>
                <th>Type of data</th>
                <th>
                  Lawful basis for processing including basis of legitimate
                  interest
                </th>
              </tr>
              <tr>
                <td>To register you as a new customer</td>
                <td>
                  (a) Identity<br></br>(b) Contact
                </td>
                <td>Performance of a contract</td>
              </tr>
            </tbody>
          </table>

          <h4 className="title">
            <a href=""> Marketing</a>
          </h4>
          <p className="description">
            <p>
              <strong>
                WE HAVE ESTABLISHED THE FOLLOWING PERSONAL DATA CONTROL
                MECHANISMS
              </strong>
            </p>
          </p>

          <h4 className="title">
            <a href=""> Promotional offers from us</a>
          </h4>
          <p className="description">
            <p>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you.
            </p>
            <p>
              You will receive marketing communications from us if you have
              requested information from us or purchased goods or services from
              us and, in each case, you have not opted out of receiving that
              marketing.
            </p>
          </p>

          <h4 className="title">
            <a href=""> Third-party marketing</a>
          </h4>
          <p className="description">
            <p>
              <strong>
                We will get your express opt-in consent before we share your
                personal data with any company outside the IYKONS group of
                companies for marketing purposes.
              </strong>
            </p>
          </p>

          <h4 className="title">
            <a href=""> Opting out</a>
          </h4>
          <p className="description">
            <p>
              <p>
                You can ask us or third parties to stop sending you marketing
                messages at any time{" "}
                <strong>
                  by logging into the website and checking or unchecking
                  relevant boxes to adjust your marketing preferences
                </strong>
                , or by{" "}
                <strong>
                  following the opt-out links on any marketing message sent to
                  you
                </strong>
                , or by <strong>mailing data@iykons.com</strong> at any time.
              </p>

              <p>
                Where you opt out of receiving these marketing messages,{" "}
                <strong>
                  this will not apply to personal data provided to us as a
                  result of a product/service purchase or other transactions
                </strong>
                .
              </p>
            </p>
          </p>

          <h4 className="title">
            <a href="https://web.archive.org/web/20220625023954/https:/www.iykons.com/cookie-policy">
              {" "}
              Cookies
            </a>
          </h4>
          <p className="description">
            <p>
              <p>
                Cookies You can set your browser to refuse all or some browser
                cookies, or to alert you when websites set or access cookies. If
                you disable or refuse cookies, please note that some parts of
                this website may become inaccessible or not function properly.
                For more information about the cookies we use, please see
                <strong>
                  <a href="https://web.archive.org/web/20220625023954/https:/www.iykons.com/cookie-policy">
                    {" "}
                    Cookies click here
                  </a>{" "}
                </strong>
              </p>
            </p>
          </p>

          <h4 className="title">Change of purpose</h4>
          <p className="descriptionj">
            <p>
              We may have to share your personal data with the parties set
              <p>
                We will only use your personal data for the purposes for which
                we collected it, unless we reasonably consider that we need to
                use it for another reason and that reason is compatible with the
                original purpose. If you wish to get an explanation as to how
                the processing for the new purpose is compatible with the
                original purpose, please contact the Data Protection Officer. If
                we need to use your personal data for an unrelated purpose, we
                will notify you and we will explain the legal basis which allows
                us to do so.
              </p>
              <p>
                Please note that we may process your personal data without your
                knowledge or consent, in compliance with the above rules, where
                this is required or permitted by law.
              </p>
            </p>
          </p>

          <h4 className="title">5. WHO WE SHARE YOUR PERSONAL DATA WITH</h4>
          

          <h4 className="title"></h4>
          <p className="description">
            <p>
              We may have to share your personal data with the parties set out
              below for the purposes set out in the table in paragraph 4 above.
            </p>

            <p>
              <strong>Internal Third Parties</strong>
              <br />
              IYKONS GROUP OF COMPANIES
            </p>

            <p>
              <strong>External Third Parties Service</strong>
              <br />
              - HM Revenue & Customs (HMRC), National Crime Agency (NCA),
              regulators and other authorities (acting as processors or joint
              controllers) in the United Kingdom who require reporting of
              processing activities in certain circumstances.
              <br />- Third parties to whom we may choose to sell, transfer, or
              merge parts of our business or our assets. Alternatively, we may
              seek to acquire other businesses or merge with them. If a change
              happens to our business, then the new owners may use your personal
              data in the same way as set out in this privacy notice.
            </p>

            <p>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </p>
          </p>

          
          <h4 className="title">6. INTERNATIONAL TRANSFERS</h4>
          

          <p>
            We may have to share your personal data with the parties set out
            below for the purposes set out in the table in paragraph 4 above.
          </p>

          <p>
            <strong>Internal Third Parties</strong>
            <br />
            IYKONS GROUP OF COMPANIES
          </p>

          <p>
            <p>
              WE SHARE YOUR PERSONAL DATA WITHIN THE IYKONS GROUP. THIS WILL
              INVOLVE TRANSFERRING YOUR DATA OUTSIDE THE UNITED KINGDOM AND
              EUROPEAN ECONOMIC AREA (EEA).
            </p>
            <p>
              WHENEVER WE TRANSFER YOUR PERSONAL DATA OUT OF THE UK AND EEA, WE
              ENSURE A SIMILAR DEGREE OF PROTECTION IS AFFORDED TO IT BY
              IMPLEMENTING SAFEGUARDS ACCORDING TO THE UNITED KINGDOM DATA
              PROTECTION ACT 2018.
            </p>
            <p>
              PLEASE CONTACT US IF YOU WANT FURTHER INFORMATION ON THE SPECIFIC
              MECHANISM USED BY US WHEN TRANSFERRING YOUR PERSONAL DATA OUT OF
              THE UK AND EEA.
            </p>
          </p>

          <h4 className="title"></h4>
          <h4>7. DATA SECURITY</h4>
          <p className="description">
            We may have to share your personal data with the parties set out
            below for the purposes set out in the table in paragraph 4 above.
          </p>

          <p>
            <strong>Internal Third Parties</strong>
            <br />
            IYKONS GROUP OF COMPANIES
          </p>
          <p>
            WE SHARE YOUR PERSONAL DATA WITHIN THE IYKONS GROUP. THIS WILL
            INVOLVE TRANSFERRING YOUR DATA OUTSIDE THE UNITED KINGDOM AND
            EUROPEAN ECONOMIC AREA (EEA).
          </p>
          <p>
            WHENEVER WE TRANSFER YOUR PERSONAL DATA OUT OF THE UK AND EEA, WE
            ENSURE A SIMILAR DEGREE OF PROTECTION IS AFFORDED TO IT BY
            IMPLEMENTING SAFEGUARDS ACCORDING TO THE UNITED KINGDOM DATA
            PROTECTION ACT 2018.
          </p>
          <p>
            PLEASE CONTACT US IF YOU WANT FURTHER INFORMATION ON THE SPECIFIC
            MECHANISM USED BY US WHEN TRANSFERRING YOUR PERSONAL DATA OUT OF THE
            UK AND EEA.
          </p>

          
          <h4 className="title">8. DATA RETENTION</h4>
        
          <h4 className="title"></h4>
                <p className="description">
                    <p>
                        <p>
                          WE SHARE YOUR PERSONAL DATA WITHIN THE IYKONS GROUP.
                          THIS WILL INVOLVE TRANSFERRING YOUR DATA OUTSIDE THE
                          UNITED KINGDOM AND EUROPEAN ECONOMIC AREA (EEA).
                        </p>
                        <p>
                          WHENEVER WE TRANSFER YOUR PERSONAL DATA OUT OF THE UK
                          AND EEA, WE ENSURE A SIMILAR DEGREE OF PROTECTION IS
                          AFFORDED TO IT BY IMPLEMENTING SAFEGUARDS ACCORDING TO
                          THE UNITED KINGDOM DATA PROTECTION ACT 2018.
                        </p>
                        <p>
                          PLEASE CONTACT US IF YOU WANT FURTHER INFORMATION ON
                          THE SPECIFIC MECHANISM USED BY US WHEN TRANSFERRING
                          YOUR PERSONAL DATA OUT OF THE UK AND EEA.
                        </p>
         
                    </p>
                  
                </p>
   

          
                <h4 className="title">9. YOUR LEGAL RIGHTS</h4>
            
          <p>
            Unless subject to an exemption under the data protection laws, you
            have the following rights with respect to your personal data:
          </p>
          <ul>
            <li>
              The right to request a copy of the personal data which we hold
              about you;
            </li>
            <li>
              The right to request that we correct any personal data if it is
              found to be inaccurate or out of date;
            </li>
            <li>
              The right to request your personal data is erased where it is no
              longer necessary to retain such data;
            </li>
            <li>
              The right to withdraw your consent to the processing at any time,
              where consent was the lawful basis for processing your data;
            </li>
            <li>
              The right to request that we provide you with your personal data
              and where possible, to transmit that data directly to another data
              controller, (known as the right to data portability), where
              applicable (i.e., where our processing is based on consent or is
              necessary for the performance of our contract with you or where we
              process your data by automated means);
            </li>
            <li>
              The right, where there is a dispute in relation to the accuracy or
              processing of your personal data, to request a restriction is
              placed on further processing;
            </li>
            <li>
              The right to object to our processing of personal data, where
              applicable (i.e., where processing is based on our legitimate
              interests (or in performance of a task in the public
              interest/exercise of official authority); direct marketing or
              processing for the purposes of scientific/historical research and
              statistics).
            </li>
          </ul>
          <p>
            If you wish to exercise any of the rights set out above, please
            contact the Data Protection Officer for subject access rights.
          </p>
          <p>
            <strong>No fee required – with some exceptions</strong>
            <br />
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable admin fee if your request is clearly unfounded,
            repetitive, or excessive. Alternatively, we may refuse to comply
            with your request in these circumstances.
          </p>
          <p>
            <strong>What we may need from you</strong>
            <br />
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <p>
            <strong>Time limit to respond</strong>
            <br />
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <h4>
            10. CHANGES TO THIS NOTICE AND YOUR DUTY TO INFORM US OF CHANGES
          </h4>

          <h4 className="title"></h4>
          <p className="descriptionj">
            <p>
              Please keep us informed if your personal data changes during your
              relationship with us. It is important that the personal data we
              hold about you is accurate and current.
            </p>
            <p>
              We reserve the right to revise this Policy or any part of it from
              time to time. Please review the Policy periodically for changes.
              If we make material changes, we will endeavor to provide notice
              ahead of such changes, such as by email or through a notice on{" "}
              <a href="https://www.iykons.com/privacy">
                https://www.iykons.com/privacy
              </a>  
            </p>
          </p>
          <h4 className="title"></h4>
          <p className="description">
            <p>
              <p>
                To exercise all relevant rights, queries, or complaints in
                relation to this policy or any other data protection matter
                between you and us, please in the first instance contact our
                DATA PROTECTION OFFICER at{" "}
                <a href="mailto:data@iykons.com">data@iykons.com</a>.
              </p>
              <p>
                If this does not resolve your complaint to your satisfaction,
                you have the right to complain with the Information
                Commissioners Office on{" "}
                <a href="tel:03031231113">03031231113</a> or via email{" "}
                <a href="https://ico.org.uk/global/contact-us/email/">
                  https://ico.org.uk/global/contact-us/email/
                </a>{" "}
                or at the Information Commissioner’s Office, Wycliffe House,
                Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.
              </p>
            </p>
          </p>
        </div>
      </section>

      <section id="servicesj">
        <div className="container">
          <header className="section-header wow fadeInUp">
            <h4></h4>
            <br />
          </header>

          <div className="row"></div>
        </div>
      </section>
      <Footer />
    </>
  );
}